import { Link } from 'gatsby';
import React from 'react';
import Layout from '../../../components/Layout/Layout';
import Seo from '../../../components/Seo/Seo';
import CollarLogo from '../../../images/collarLogo.svg';
import { kebabCase } from '../../../utils';
import { BranchesContainer, BranchesList, BranchItemContainer, BranchLink, BranchListItem, BranchLogo, BranchTitle, LogoContainer } from './styled';

export default function ProvidersView({ pageContext }) {
  const providers = pageContext.providers;
  return (
    <Layout>
      {/* <BackImage to="../brands">
        <span>Switch to brands</span>
      </BackImage> */}
      <BranchesContainer>
        <BranchesList>
          {providers.map((provider: Record<string, any>) => {
            return (
              <BranchListItem key={provider.id}>
                <BranchItemContainer primaryColor={provider.branches[0].colors.primary}>
                  <BranchLink to={`${kebabCase(provider.name)}`}>
                    <BranchLogo src={provider.branches[0].photos.logo} alt={provider.name} />
                  </BranchLink>
                </BranchItemContainer>

                <BranchTitle>{provider.name}</BranchTitle>
              </BranchListItem>
            );
          })}
        </BranchesList>
        <LogoContainer>
          <Link to="https://collar.pet/download" target="_blank" rel="noopener noreferrer">
            <CollarLogo />
          </Link>
        </LogoContainer>
      </BranchesContainer>
    </Layout>
  );
}

export const Head = ({ pageContext, data }) => {
  const providers = pageContext.providers;
  const randomProvider = providers[Math.floor(Math.random() * providers.length)];
  return <Seo title="Brands" description="Book services for your pet from our trusted providers." image={randomProvider?.branches?.[0]?.photos?.logo} />;
};
